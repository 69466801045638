import { Spin } from 'antd';
import React, { Component } from 'react';
import { SelectElement } from '../../../../../../FormElements/SelectElement';
import { getTextForTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { getTranslatedTextPayload } from './helper';
import "./index.scss";
class TranslationSection extends Component {

    onChangeTranslation = (value) => {
        let payload = getTranslatedTextPayload(this.props, value)
        this.props.onChangeLanguage(payload)
        //     (selectedLanguage) => {
        //     if (selectedLanguage !== "English" && selectedLanguage !== "Korean")
        //         this.props.translateSurvey(payload)
        // })

    }

    render() {
        return (

            <Spin spinning={this.props.surveyTranslateLoading}>
                <div className='translation-block'>
                    <div className='translation-block__content'>
                        <h3>{getTextForTranslation({ language: this.props.selectedLanguage, defaultTexts: this.props.defaultTexts, translatedTexts: this.props.translatedTexts, key: "languageSelect" })}</h3>
                        <div className='translation-select'>
                            <SelectElement
                                className=''
                                single={true}
                                onChange={(value) => {
                                    this.onChangeTranslation(value)
                                }}
                                value={this.props.selectedLanguage}
                                disableSearch={true}
                                optionList={this.props.questionDetails.translationLanguages} />
                        </div>
                    </div>
                </div>
            </Spin>


        );
    }
}

export default TranslationSection;
