export const fetchSurveyReportStart = (state, payload) => {
  return {
    ...state,
    surveyReportApi: {
      isProcessing: true,
      error: false,
      reason: '',
      surveyReport: { dimensionTable: [], itemTable: [] },
    },
  };
};

export const fetchSurveyReportSuccess = (state, payload) => {
  return {
    ...state,
    surveyReportApi: {
      isInitialReportLoaded: true,
      isProcessing: false,
      error: false,
      reason: '',
      surveyReport: payload.data,
    },
  };
};

export const fetchSurveyReportFail = (state, payload) => {
  return {
    ...state,
    surveyReportApi: {
      isProcessing: false,
      isInitialReportLoaded: false,
      error: true,
      reason: payload.error,
      surveyReport: { dimensionTable: [], itemTable: [] },
    },
  };
};
