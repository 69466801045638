import React, { Component } from 'react'
import parse from "html-react-parser";
import { ButtonElement } from '../../../../../../FormElements/ButtonElement';
import { getQuestionTranslation, getTextForTranslation, getWelcomeMessageText } from '../../../../../../utils/newAttendSurveyUtils';
import { KEY_CONSTANTS } from '../../constants';
import "./index.scss";
export default class DescriptiveText extends Component {
    render() {
        return (
            <div className="newsurvey-block ">
            <div className='descriptive-block'>
                <div className='descriptive-block__content'>
                    {this.props.questionDetails.isWelcomeMessage ? parse(getWelcomeMessageText({
                        language: this.props.selectedLanguage,
                        currentMessage: this.props.questionDetails.questions[0].question,
                        translatedTexts: this.props.translatedTexts,
                        translatedDescription: this.props.surveyQuestionSetData.translatedDescription,
                        key: KEY_CONSTANTS.TRANSLATED_DESCRIPTION,
                        aboutSurveyTranslation: this.props.surveyQuestionSetData && this.props.surveyQuestionSetData.aboutSurveyTranslation,
                        relation: this.props.relation
                    })) : parse(getQuestionTranslation({ language: this.props.selectedLanguage, question: this.props.questionDetails.questions[0], key: KEY_CONSTANTS.QUESTION }))}
                </div>
                <div className='descriptive-block__button'>
                    {/* <ButtonElement type={'primary'} className='descriptive-btn' onClick={() => this.props.handleGoPrevAndNext(false, false)}>
                        {getTextForTranslation({ language: this.props.selectedLanguage, defaultTexts: this.props.defaultTexts, translatedTexts: this.props.translatedTexts, key: KEY_CONSTANTS.CONTINUE_BUTTON })}
                    </ButtonElement> */}
                </div>
            </div>
            </div>
        )
    }
}
