import { deepCopy } from "../../../../../../utils/deepCopy"
import { checkIfJson } from "../../../../../../utils/newAttendSurveyUtils"
import { DEFAULT_LANGUAGE } from "./constants"

export const getPriorityListStaticIds = (priorityList) => {
    let staticIds = []
    if (priorityList) {
        let priorityListCopy = JSON.parse(priorityList)
    priorityListCopy.forEach((item) => {
        if (item.answer) {
            staticIds.push(item.id)
        }
    })
    }
    return staticIds

}

export const isDragHandleNeeded = (event) => {
    let droppableId = event.destination && event.destination.droppableId
    if (droppableId) {
        return checkIfJson(droppableId)

    }

}

export const checkIdsExist = (questionDetails, draggedDetailsId) => {
    if (questionDetails && questionDetails.length > 0 && draggedDetailsId && draggedDetailsId.length > 0) {
        for (let details of questionDetails) {
            let idFound = draggedDetailsId.some(id => id === details.id);
            // If the current ID is not found, return false immediately
            if (!idFound) {
                return false;
            }
        }
        // If all IDs are found, return true
        return true;
    }

    return false;
}

export const getTextToDisplay = ({ key, language, questionDetails }) => {
    if (language !== DEFAULT_LANGUAGE) {
        if (questionDetails[`${language}_${key}`]) {
            return questionDetails[`${language}_${key}`]
        }
        else if (questionDetails[key]) {
            return questionDetails[key]
        }
        else {
            return false
        }
    }
    else {
        if (questionDetails[key]) {
            return questionDetails[key]
        }
    }

}