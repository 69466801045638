import React, { Component } from 'react'
import './index.scss'
import ScrollBar from '../../../../../../Packages/ScrollBar';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { checkIdsExist, getTextToDisplay, getPriorityListStaticIds, isDragHandleNeeded } from './helper';
import parse from "html-react-parser";
import { NESTED_NON_MAPPING_KEY, NON_MAPPING_KEYS, TEXT_SECTION, TEXT_SECTION_180_SELF, TOPIC_WISE_MAPPING } from './constants';
import ErrorBlock from '../ErrorBlock';
import SVGIcons from '../../../../../../Components/SVGIcons';
import { KEY_CONSTANTS } from '../../constants';
import { FEEDBACK_360_SURVEY } from '../../../../../../../config/questionTypeConstants';
import { getQuestionTranslation, getTextForManualTranslation } from '../../../../../../utils/newAttendSurveyUtils';

export default class FeedBackRanking extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
      this.props.behaviourDragUpdate()
    }
  renderAnsweredQuestions = (answeredQuestionDetails, questionCount) => {
    let priorityAddedIds = this.props.questionDetails.questions && this.props.questionDetails.questions.length > 0 ? getPriorityListStaticIds(this.props.questionDetails.questions[0].answer) : []
        return (
          <div className='feedbackDrak__left'>
            <h3>Total Behaviors: {questionCount} </h3>
            <ScrollBar className="drakscroll">
                  <div className='drakscroll__cnt'>
            {answeredQuestionDetails && Object.keys(answeredQuestionDetails).map((answeredColumnObject, key) => (

              <>
          
              {!NON_MAPPING_KEYS.includes(answeredColumnObject) && (
             
                  <div className='feedbackDrak__left--area' key={key}>
                  
                 
                      <Droppable droppableId={answeredQuestionDetails[answeredColumnObject].answerLabel} key={answeredQuestionDetails[answeredColumnObject].answerLabel}>
                        {(provided) => (
                          <div
                            id={answeredQuestionDetails[answeredColumnObject].answerLabel}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                       
                          >
                            <h4>{answeredQuestionDetails[answeredColumnObject].answerLabel}</h4>
                            {answeredQuestionDetails[answeredColumnObject] && answeredQuestionDetails[answeredColumnObject].questions && answeredQuestionDetails[answeredColumnObject].questions.map((questionDetails, index) => (
                              <>
                                {!priorityAddedIds.includes(questionDetails.id) && (
                                  < Draggable key={JSON.stringify(questionDetails)} draggableId={JSON.stringify(questionDetails)} index={index}>
                                    {(provided) => (
                                      <div
                                        // className={style.pairingArea}
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                      >
                                        <div
                                          {...provided.dragHandleProps}
                                          className='feedbackbox'
                                        >
                                          {/* <span>{questionDetails.label}</span> */}
                                          <div className='feedbackbox__content'>
                                            {getQuestionTranslation({ language: this.props.selectedLanguage, question: questionDetails, key: KEY_CONSTANTS.QUESTION })}
                                            {/* {questionDetails.question} */}
                                          </div>

                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )}
                              </>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      </div>

            
                    )}
                
                   
                  </>

            ))}
            </div>
                     </ScrollBar>
          </div>
        );
      };
      

  renderAnsweredQuestionsForTopicWise = (answeredQuestionDetails, questionCount) => {
    let priorityAddedIds = this.props.questionDetails.questions && this.props.questionDetails.questions.length > 0 ? getPriorityListStaticIds(this.props.questionDetails.questions[0].answer) : []
    return (
      <div className='feedbackDrak__left'>
        <h3>Total Behaviors: {questionCount}</h3>
        <ScrollBar className="drakscroll">
          <div className='drakscroll__cnt'>
            {answeredQuestionDetails && Object.keys(answeredQuestionDetails).map((answeredColumnObject, key) => (
              <>
                {!NON_MAPPING_KEYS.includes(answeredColumnObject) && (
                  <div className='feedbackDrak__left--area' key={key}>
                    <h4>{answeredQuestionDetails[answeredColumnObject].answerLabel}</h4>
                    {Object.keys(answeredQuestionDetails[answeredColumnObject]).map((nestedColId) => (
                      <>
                        {!NESTED_NON_MAPPING_KEY.includes(nestedColId) && (
                          <Droppable droppableId={answeredQuestionDetails[answeredColumnObject].answerLabel + answeredQuestionDetails[answeredColumnObject][nestedColId].answerLabel} key={answeredQuestionDetails[answeredColumnObject].answerLabel + answeredQuestionDetails[answeredColumnObject][nestedColId].answerLabel}>
                            {(provided) => (
                              <div
                                id={answeredQuestionDetails[answeredColumnObject].answerLabel}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <h2>{!checkIdsExist(answeredQuestionDetails[answeredColumnObject][nestedColId].questions, priorityAddedIds) && answeredQuestionDetails[answeredColumnObject][nestedColId].answerLabel}</h2>
                                {answeredQuestionDetails[answeredColumnObject][nestedColId] && answeredQuestionDetails[answeredColumnObject][nestedColId].questions && answeredQuestionDetails[answeredColumnObject][nestedColId].questions.map((questionDetails, index) => (
                                  <>
                                    {!priorityAddedIds.includes(questionDetails.id) && (
                                      <Draggable key={JSON.stringify(questionDetails)} draggableId={JSON.stringify(questionDetails)} index={questionDetails.index}>
                                        {(provided) => (
                                          <div
                                            // className={style.pairingArea}
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                          >
                                            <div
                                              {...provided.dragHandleProps}
                                              className='feedbackbox'
                                            >
                                              {/* <span>{questionDetails.label}</span> */}
                                              <div className='feedbackbox__content'>
                                                {getQuestionTranslation({ language: this.props.selectedLanguage, question: questionDetails, key: KEY_CONSTANTS.QUESTION })}

                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    )}
                                  </>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </>
            ))}
          </div>
        </ScrollBar>
      </div>

    );
  };

    dragEnd = (event) => {
      if (event.destination && isDragHandleNeeded(event)) {
            this.props.handleDragEndPriority({ blockId: this.props.questionDetails.blockId, destinationDetails: event.destination, sourceDetails: JSON.parse(event.draggableId) })
        }

    }

  renderDropZone = (questionCount) => {
    let instructionHeader = this.getDisplayTextForInstructionHeader()
        let currentDetails = this.props.questionDetails
    let rankingCount = currentDetails.totalRanking
    let droppableArray = Array.from({ length: rankingCount }, (_, i) => {
            return ({
                label: i + 1
            })
        })
        return (
            <>
                <div className='feedbackDrak__right'>
              <h3>{instructionHeader ? instructionHeader : "DRAG AND DROP THE BEHAVIORS YOU WANT TO PRIORITIZE"}</h3>
                    {droppableArray.map((item, key) => {
                      let answer = currentDetails.questions.length > 0 && currentDetails.questions[0].answer ? JSON.parse(currentDetails.questions[0].answer).find((answer) => answer.answer === item.label.toString()) : undefined
                        return (
                            <>
                                <Droppable droppableId={JSON.stringify({ id: item.label })} key={key} >
                                    {(provided) => (
                                        <div
                                            id={key}
                                // {...provided.droppableProps}
                                // ref={provided.innerRef}
                                >
                                  <div className='feedbackDrak__right--area'
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                                {answer ? (
                                                    <>
                                        <span className='dragged-question'>
                                          {/* {answer.question} */}
                                          {getQuestionTranslation({ language: this.props.selectedLanguage, question: answer, key: KEY_CONSTANTS.QUESTION })}

                                        </span>
                                                        <span className='delete-icon' onClick={() => this.handleDeleteItem(key, item, answer)}>
                                                            <SVGIcons type={'SVG-delete'} />
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span>{item.label}</span>
                                                )}
                                    {provided.placeholder}
                                            </div>                                                
                                            {/* {provided.placeholder} */}
                                        </div>)}
                                </Droppable>
                            </>
                        )

                    })}
                </div>
            </>

        )
    }

    handleDeleteItem = (index, item, selectedAnswer) => {
      this.props.handleBehaviourDragDelete({selectedAnswer,index,item,blockId:this.props.questionDetails.blockId})
  }

  getDisplayTextForInstructionHeader = () => {
    if (this.props.relation === KEY_CONSTANTS.SELF) {
      return getTextToDisplay({
        key: 'selfInstructionHeader',
        language: this.props.selectedLanguage,
        questionDetails: this.props.questionDetails

      })
    }
    else {
      return getTextToDisplay({
        key: 'instructionHeader',
        language: this.props.selectedLanguage,
        questionDetails: this.props.questionDetails

      })
    }
  }

  getTextToDisplayBasedOnRelation = () => {
    if (this.props.relation === KEY_CONSTANTS.SELF && this.props.questionDetails.selfMainHeading) {
      if(this.props.questionDetails.goalId === "feedback360_strengthScaleDrag"){
        return getTextForManualTranslation({
          language: this.props.selectedLanguage,
          defaultTexts: this.props.defaultTexts,
          translatedTexts: this.props.questionDetails,
          key: KEY_CONSTANTS.STRENGTH_SELF_MAIN_HEADING,
          genericKey: KEY_CONSTANTS.SELF_MAIN_HEADING,

        })
      }else{
        return getTextForManualTranslation({
          language: this.props.selectedLanguage,
          defaultTexts: this.props.defaultTexts,
          translatedTexts: this.props.questionDetails,
          key: KEY_CONSTANTS.IMPROVEMENT_SELF_MAIN_HEADING,
          genericKey: KEY_CONSTANTS.SELF_MAIN_HEADING,
        })
      }
    }
    else if (this.props.questionDetails.mainHeading) {
      if(this.props.questionDetails.goalId === "feedback360_strengthScaleDrag"){
        return getTextForManualTranslation({
          language: this.props.selectedLanguage,
          defaultTexts: this.props.defaultTexts,
          translatedTexts: this.props.questionDetails,
          key: KEY_CONSTANTS.STRENGTH_MAIN_HEADING,
          genericKey: KEY_CONSTANTS.MAIN_HEADING,

        })
      }else{
        return getTextForManualTranslation({
          language: this.props.selectedLanguage,
          defaultTexts: this.props.defaultTexts,
          translatedTexts: this.props.questionDetails,
          key: KEY_CONSTANTS.IMPROVEMENT_MAIN_HEADING,
          genericKey: KEY_CONSTANTS.MAIN_HEADING,

        })
      }
    }
    return false
  }

  render() {
    let answeredQuestionDetails = this.props.questionDetails.questions[0];
    let mainHeadingText = this.getTextToDisplayBasedOnRelation()
    let questionCount = 0
    if (answeredQuestionDetails) {
      for (const key in answeredQuestionDetails) {
        if (answeredQuestionDetails[key].questions && Array.isArray(answeredQuestionDetails[key].questions)) {
          questionCount += answeredQuestionDetails[key].questions.length;
        }
      }

    }


        return (
          <>
            <div className='feedbackArea'>
              {mainHeadingText ? parse(mainHeadingText) : this.props.surveyQuestionSetData.projectType === FEEDBACK_360_SURVEY.DYNAMIC_180 ?
              (this.props.relation === KEY_CONSTANTS.SELF ?
                parse(TEXT_SECTION_180_SELF[this.props.questionDetails.goalId]) :
                parse(TEXT_SECTION[this.props.questionDetails.goalId])
              ) :
              parse(TEXT_SECTION[this.props.questionDetails.goalId])
                }
            </div>
            <div className='feedbackDrak'>
                <DragDropContext onDragEnd={this.dragEnd} onDragUpdate={this.dragUpdates}>
                {this.props.questionDetails.displayMappingLogic && this.props.questionDetails.displayMappingLogic === TOPIC_WISE_MAPPING ? this.renderAnsweredQuestionsForTopicWise(answeredQuestionDetails, questionCount) : this.renderAnsweredQuestions(answeredQuestionDetails, questionCount)}
                {this.renderDropZone(questionCount)}
                </DragDropContext>
            </div>
            <ErrorBlock manageScroller={this.props.manageScroller} />
          </>
        )
    }
}
