export var QUESTION_TYPE = {
  MULTIPLE_OPTION: "multipleOption",
  DEMOGRAPHICS: "multipleChoice",
  TEXT_ENTRY: "textEntry",
  LIKERT: "likert",
  MATRIX: "matrix",
  RANKING: "ranking",
  NET_PROMOTER: "employerNetPromoterScore",
  YES_OR_NO: "yesOrNo",
  RATING: "ratingScale",
  LIKERT_MATRIX: "Matrix",
  TEXT_ENTRY_COMMENTS: "Text Entry",
  SUMMARY_TYPE: "summaryRating",
  PRIORITY_DRAG: "priorityDrag",
  PRIORITY_COMMENTS: "priorityComments"
};
export var FEEDBACK_360_TYPE = ["360FeedbackDynamic", "360Feedback", "360FromScratch", "180FeedbackDynamic", "360FeedbackDynamicMascoCohort", "360FeedbackDynamicForAppliedMaterials", "360FeedbackDynamicForAppliedMaterialsDirectors"];
export var DYNAMIC_360_GOAL_IDS = {
  STRENGTH: "dynamic360Strength",
  STRENGTH_DRAG: "feedback360_strengthScaleDrag",
  IMPROVEMENT_DRAG: "feedback360_improvementScaleDrag",
  STRENGTH_180: "dynamic180Strength",
  IMPROVEMENT_DRAG_180: "feedback180_improvementScaleDrag",
  STRENGTH_DRAG_180: "feedback180_strengthScaleDrag"
};
export var FEEDBACK_360_SURVEY = {
  DYNAMIC: "360FeedbackDynamic",
  COMMENTS_ON_IMPROVEMENT: "commentsOnImprovement",
  STRENGTH_DRAG: "feedback360_strengthScaleDrag",
  COMMENTS_ON_STRENGTH: "commentsOnStrength",
  IMPROVEMENT_DRAG: "feedback360_improvementScaleDrag",
  IMPROVEMENT_DRAG_180: "feedback180_improvementScaleDrag",
  STRENGTH_DRAG_180: "feedback180_strengthScaleDrag",
  DYNAMIC_180: "180FeedbackDynamic",
  DYNAMIC_360_COHORT_MASCO: "360FeedbackDynamicMascoCohort",
  DYNAMIC_360_APPLIED_MATERIALS: "360FeedbackDynamicForAppliedMaterials",
  DYNAMIC_360_APPLIED_MATERIALS_DIRECTORS: "360FeedbackDynamicForAppliedMaterialsDirectors"
};
export var FEEDBACK_GOAL_IDS = ["feedback360_improvementScaleDrag", "commentsOnImprovement", "feedback360_strengthScaleDrag", "commentsOnStrength", "feedback180_improvementScaleDrag", "feedback180_strengthScaleDrag"];
export var FEEDBACK_360_HRIS_TYPE = "feedback360Hris";
export var PARENT_COHORT_HRIS = "cohortHris";
export var CHILD_COHORT_HRIS = "childCohortHris";
export var REDIRECTION_CONSTANTS = {
  FINAL_PAGE: "finalPage"
};
export var COHORT_SURVEY_TYPE = "cohortSurvey";
export var FEEDBACK_360_FOR_PARTICULAR_ORG_IDS = ["24xrq58lumoaynk", "fkk6e78lvgpk07h", "21bngf8lweq94eb", "22wh2e8lwioqh3t"];