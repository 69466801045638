import React, { Component } from 'react'
import { Input } from 'antd';
import './index.scss'
import { CHECKBOX_TEXT, ERROR_MESSAGE, SELF, TEXT_SECTION, TEXT_SECTION_180, TEXT_SECTION_180_SELF } from './constants';
import parse from "html-react-parser";
import ErrorBlock from '../ErrorBlock';
import { deepCopy } from '../../../../../../utils/deepCopy';
import { FEEDBACK_360_SURVEY } from '../../../../../../../config/questionTypeConstants';
import CheckboxElement from '../../../../../../FormElements/CheckboxElement';
import { KEY_CONSTANTS } from '../../constants';
import { getQuestionTranslation } from '../../../../../../utils/newAttendSurveyUtils';
import { getTextToDisplay } from '../FeedBackRanking/helper';
const { TextArea } = Input;

export default class FeedbackTextEntry extends Component {
    constructor(props) {
        super(props);
    }

    handleChangeTextPriority = (event, currentItem) => {
        this.props.handleChangePriorityText({ textAnswer: event.target.value, currentItemId: currentItem.qid, blockId: this.props.questionDetails.blockId })
    }

    handleChangeCheckboxChange = (event, currentItem) => {
        this.props.handleChangeNotAnswerNeedCheckBox({ checked: event.value, blockId: this.props.questionDetails.blockId, currentItemId: currentItem.qid })
    }

    renderPrioritySection = (priorityItem) => {
        let priorityAnswer = priorityItem
        return (
            <>
                {priorityAnswer && <div className='feedbackBlock__area'>
                    <div className='feedbackBlock__area--textsection'>
                        <div className='tag-area'>
                            <span>Priority {priorityItem.priorityValue}</span>
                            <span>{priorityAnswer.label}</span>
                        </div>

                        <div>
                            {getQuestionTranslation({ language: this.props.selectedLanguage, question: priorityAnswer, key: KEY_CONSTANTS.QUESTION })}
                        </div>

                    </div>
                    <div className='feedbackBlock__area--inputsection'>
                        <TextArea
                            className={`openend-textarea ${priorityAnswer.checkboxValue ? 'disabledtext' : ""}`}
                            test-name="text-entry"
                            value={priorityAnswer.answer}
                            onChange={(event) => { this.handleChangeTextPriority(event, priorityAnswer) }}
                            placeholder={"Enter response in any language"}
                            disabled={priorityAnswer.checkboxValue}
                        />
                        <div className='checkbox-wrapper'>
                            {priorityAnswer.isCheckboxNeeded && <CheckboxElement
                                label={CHECKBOX_TEXT}
                                handleChange={(event) => this.handleChangeCheckboxChange(event, priorityAnswer)}
                                selected={priorityAnswer.checkboxValue}
                                disabled={false}
                            />}
                        </div>
                    </div>
                </div>}
            </>
        )
    }


    getDisplayTextForInstructionHeader = () => {
        if (this.props.relation === KEY_CONSTANTS.SELF) {
            return getTextToDisplay({
                key: 'selfInstructionHeader',
                language: this.props.selectedLanguage,
                questionDetails: this.props.questionDetails

            })
        }
        else {
            return getTextToDisplay({
                key: 'instructionHeader',
                language: this.props.selectedLanguage,
                questionDetails: this.props.questionDetails

            })
        }
    }

    render() {
        let questionCopy = deepCopy(this.props.questionDetails.questions)
        let header = this.getDisplayTextForInstructionHeader()
        questionCopy.sort((firstElement, secondElement) => { return parseInt(firstElement.priorityValue) - parseInt(secondElement.priorityValue) })

        return (
            <div className='feedbackBlock'>
                <h6>
                    {header ? parse(header) : this.props.surveyQuestionSetData && this.props.surveyQuestionSetData.projectType === FEEDBACK_360_SURVEY.DYNAMIC_180 ? 
                    (this.props.relation === SELF ?  parse(TEXT_SECTION_180_SELF[this.props.questionDetails.goalId]) : parse(TEXT_SECTION_180[this.props.questionDetails.goalId])) :
                        parse(TEXT_SECTION[this.props.questionDetails.goalId])
                    }
                </h6>
              
                {questionCopy.map((item) => this.renderPrioritySection(item))}
                <ErrorBlock manageScroller={this.props.manageScroller} />
            </div>
        )
    }
}
